import { useCallback, useEffect, useMemo } from 'react';

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { meshGatewayClient, tournamentsClient } from 'src/apollo/client';
import { useOrgId, useSelectedFacility } from 'src/apollo/local-state';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { GetOrganisationType, GetOrganisationTypeVariables } from 'src/graphql-types/GetOrganisationType';
import { GetOrgHierarchy, GetOrgHierarchyVariables } from 'src/graphql-types/GetOrgHierarchy';
import { GetSanctioningHierarchy, GetSanctioningHierarchyVariables } from 'src/graphql-types/GetSanctioningHierarchy';
import { retrieveUserVenues } from 'src/utils/storage/local-storage';

import { getToken, isCognito, resetSession, userIsGlobalAdmin } from './auth';
import { StaffScope, VenueSystemRole } from './enums';
import { GET_ORGANISATION_TYPE } from './organisations-auth-queries';
import { ACCESS_RULES, AccessRule } from './rules';
import { GET_ORG_HIERARCHY, GET_SANCTIONING_HIERARCHY } from './tournaments-auth-queries';

export const useVenuePermissions = () => {
  const selectedFacility = useSelectedFacility();

  return useMemo<{ role: VenueSystemRole; scope: StaffScope }>(() => {
    const isGlobalAdmin = userIsGlobalAdmin();

    const role: VenueSystemRole = isGlobalAdmin
      ? VenueSystemRole.SUPER_ADMINISTRATOR
      : selectedFacility?.VenueSystemRoles ?? VenueSystemRole.NONE;

    const scope: StaffScope =
      isGlobalAdmin || getClientConfig().ignoreScopes
        ? StaffScope.SUPERADMIN |
          StaffScope.TOURNAMENTS |
          StaffScope.STAFF |
          StaffScope.RANKINGS |
          StaffScope.PLAYERS |
          StaffScope.PLAYTRACKER |
          StaffScope.SUSPENSIONS |
          StaffScope.REPORTS |
          StaffScope.MEMBERSHIPS |
          StaffScope.ORGANISATIONS |
          StaffScope.PROGRAMMES |
          StaffScope.EVENTS |
          StaffScope.COACHES |
          StaffScope.LEAGUES |
          StaffScope.AREAS |
          StaffScope.REPORTS
        : selectedFacility?.Scope ?? StaffScope.NONE;
    return { role, scope };
  }, [selectedFacility]);
};

export const useHasScope = (scopes: StaffScope[]) => {
  const p = useVenuePermissions();
  return scopes.every((s) => s & p.scope);
};

// Ommiting scopes, roles or requiresGlobalAdmin means there is no requirement
// for the user to have the respective permissions. If an array of roles/scopes is
// included the user must meet all requirements in each of the arrays.

export const useProtectedRoute = ({
  roles,
  scopes,
  requiresGlobalAdmin,
  redirect = '/',
}: {
  roles?: VenueSystemRole[];
  scopes?: StaffScope[];
  redirect?: string;
  requiresGlobalAdmin?: boolean;
}) => {
  const p = useVenuePermissions();
  if (typeof window !== 'undefined') {
    const hasRequiredRoles =
      !roles ||
      roles.every((r) => {
        // new way - we need to check the scope
        if (r === VenueSystemRole.ADMINISTRATOR) {
          return p.scope & StaffScope.ADMIN;
        }
        if (r === VenueSystemRole.SUPER_ADMINISTRATOR) {
          return p.scope & StaffScope.SUPERADMIN;
        }
        return r & p.role;
      });

    const hasRequiredScope = !scopes || scopes.every((s) => s & p.scope);
    const hasRequiredGlobalAdminRole = !requiresGlobalAdmin || userIsGlobalAdmin();

    if (!hasRequiredScope || !hasRequiredRoles || !hasRequiredGlobalAdminRole) {
      navigate(redirect);
    }
  }
};

export const usePrivateRoute = () => {
  const c = useApolloClient();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      if (isCognito() && !getClientConfig().isSaaS) {
        const authPingFetch = fetch(`${getEnvConfig().CLUBSPARK_CLASSIC_URL}/Account/Provider`, {
          credentials: 'include',
        });
        const venues = retrieveUserVenues();

        if (!venues?.length && !userIsGlobalAdmin()) {
          resetSession(c, location.pathname);
        } else {
          // This is JSONP but it's simpler just to check the string, we don't need the JSONP
          const authPing = await authPingFetch;
          if (!authPing.ok || (await authPing.text()) === "provider('None')") {
            resetSession(c, location.pathname);
          }
        }
      } else if (!getToken()) {
        resetSession(c, location.pathname);
      }
    })();
  }, []);
};

export const useScopeAccess = () => {
  const { scope } = useVenuePermissions();

  const getRouteRule = useCallback((route: string): AccessRule | undefined => {
    return ACCESS_RULES.find((r) => {
      if (r.withDynamicId) {
        return !!route.split(`${r.route}/`)[1]?.length;
      }
      return route.startsWith(r.route);
    });
  }, []);

  const hasAccess = useCallback(
    (rule?: AccessRule) => {
      if (!rule) {
        return true;
      }
      if (rule.requiresGlobalAdmin) {
        return userIsGlobalAdmin();
      }
      return rule.scope.every((s) => {
        return s & scope;
      });
    },
    [scope],
  );

  const getAccessInfo = useCallback(
    (route: string) => {
      const rule = getRouteRule(route);
      return { hasAccess: hasAccess(rule), rule };
    },
    [getRouteRule, hasAccess],
  );
  return { getAccessInfo };
};

export const useRouteRestrictions = () => {
  const { getAccessInfo } = useScopeAccess();
  const { pathname } = useLocation();
  const { hasAccess, rule } = useMemo(() => getAccessInfo(pathname), [pathname]);

  if (!hasAccess && typeof window !== 'undefined') {
    navigate(rule?.redirect ?? '/access-denied');
  }
};

export const useOrgHierarchy = () => {
  const { disableOrgHierarchyRestrictions } = getClientConfig();
  const tournamentsActive = useMemo(() => !getClientConfig().disableModules?.includes('tournaments'), []);
  const orgId = useOrgId();
  const [getOrgHierarchy, { data }] = useLazyQuery<GetOrgHierarchy, GetOrgHierarchyVariables>(GET_ORG_HIERARCHY, {
    variables: { orgId },
    client: tournamentsClient,
  });
  useEffect(() => {
    if (tournamentsActive && !disableOrgHierarchyRestrictions) getOrgHierarchy();
  }, [tournamentsActive, getOrgHierarchy]);

  return data?.organisation?.hierarchy;
};

// Hierarchy from top -> bottom e.g. [nationalId, sectionId, districtId]
export function useSanctioningHierarchy(tournamentId?: string) {
  const [getHierarchy, { data }] = useLazyQuery<GetSanctioningHierarchy, GetSanctioningHierarchyVariables>(
    GET_SANCTIONING_HIERARCHY,
    { client: tournamentsClient },
  );
  useEffect(() => {
    tournamentId && getHierarchy({ variables: { tournamentId } });
  }, [tournamentId]);
  return data?.tournament?.sanctionApprovalHierarchy?.map((o) => o.id as string);
}

export function useIsSanctioningBody(tournamentId?: string) {
  const sanctioningHierarchy = useSanctioningHierarchy(tournamentId);
  const orgId = useOrgId();
  const isGlobalAdmin = useMemo(userIsGlobalAdmin, []);
  const isSanctioningBody = useMemo(
    () => !!sanctioningHierarchy?.map((i) => i.toUpperCase())?.includes(orgId?.toUpperCase()),
    [sanctioningHierarchy, orgId],
  );

  return isGlobalAdmin || isSanctioningBody;
}

export const useNationalOnlyRoute = () => {
  const orgHierarchy = useOrgHierarchy();
  if (orgHierarchy && orgHierarchy?.length !== 1 && typeof window !== 'undefined') {
    navigate('/access-denied');
  }
};

export enum OrgLevelEnum {
  NATIONAL,
  SECTION,
  DISTRICT,
}

const getOrgLevel = (level?: number): OrgLevelEnum | undefined => {
  switch (level) {
    case 1:
      return OrgLevelEnum.NATIONAL;
    case 2:
      return OrgLevelEnum.SECTION;
    case 3:
      return OrgLevelEnum.DISTRICT;
  }
};

export const useOrgLevel = (): {
  isNational: boolean;
  isSection: boolean;
  isDistrict: boolean;
  level?: OrgLevelEnum;
} => {
  const orgHierarchy = useOrgHierarchy();

  const tournamentsActive = useMemo(() => !getClientConfig().disableModules?.includes('tournaments'), []);

  return {
    isNational: orgHierarchy?.length === 1 || !tournamentsActive,
    isSection: orgHierarchy?.length === 2,
    isDistrict: orgHierarchy?.length === 3,
    level: tournamentsActive ? getOrgLevel(orgHierarchy?.length) : OrgLevelEnum.NATIONAL,
  };
};

export const useOrgType = () => {
  const orgId = useOrgId();
  const { data: orgType } = useQuery<GetOrganisationType, GetOrganisationTypeVariables>(GET_ORGANISATION_TYPE, {
    client: meshGatewayClient,
    variables: {
      organisationId: orgId,
    },
  });

  return orgType?.getOrganisationById?.organisationType;
};
