import { wrapRootBrowser } from './src/apollo/wrap-root'
import './src/utils/i18n'
import './src/utils/dayjs'
import ReactDOM from 'react-dom'

export const wrapRootElement = wrapRootBrowser

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

